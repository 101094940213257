


// BIO LINE CLAMPING (hides bios longer than 5 lines)

// only show bio read more when height exceeds 150
var minHeight = 149;
$('.read-more').show();

$(function () {
        

    // restrict visible height and hide read more button
    $(".bio.line-clamp").each(function () {
        var $this = $(this),
            $readmore = $('.read-more', this),
            h = $this.height();
        $readmore.css({ 'display': 'block' });
        if (h < minHeight) {
            $readmore.hide();
        }
    });


    // reveal hidden portion of bio
    var $el, $ps, $up, totalHeight;

    $(".bio .button").click(function() {

        totalHeight = minHeight;

        $el = $(this);
        $p  = $el.parent();
        $up = $p.parent();
        $ps = $up.find("p:not('.read-more')");
        
        $ps.each(function() {
            totalHeight += $(this).outerHeight();
        });
                
        console.log($up.height());
        //$up.css({ "height": $up.height(), "max-height": 9999 })
        $up.css({ "height": $up.height(), "max-height": 9999 })
            .animate({"height": totalHeight},function(){
                $up.css({
                    // allow brower resize
                    "height":"auto",
                    "overflow":"visible"
                });
            });
        $p.fadeOut();
        return false;
            
    });
});


// temporary header history back 
//$('.header-back .back').on("click", function (e) {
//    e.preventDefault;
//    window.history.go(-1);
//});