

// ES5 version

'use strict';

// youtube and vimeo embedded video player
if ($('#videoUrl').length) {
    $(function () {
        var playButton = document.querySelector('.video-container a');
        if (playButton) {
            playButton.addEventListener('click', playVideo);
        }

        var videoUrl = $('#videoUrl').attr('href');
        var videoId = videoUrl.split('.com/')[1];
        if (videoUrl.indexOf('vimeo') >= 0) {
            // vimeo
            videoId = parseVimeoId(videoUrl);
            $.ajax({
                type: 'GET',
                url: 'https://vimeo.com/api/v2/video/' + videoId + '.json',
                jsonp: 'callback',
                dataType: 'jsonp',
                success: function success(data) {
                    var thumbnail_src = data[0].thumbnail_large;
                    $('#thumb_wrapper').append('<img src="' + thumbnail_src + '"/>');
                }
            });
        } else {
            // youtube
            videoId = videoId.split('v=')[1];
            $('#thumb_wrapper').append('<img src="https://img.youtube.com/vi/' + videoId + '/hqdefault.jpg"/>');
        }

        function parseVimeoId(url) {
            var vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
            var parsed = url.match(vimeoRegex);
            return parsed[1];
        };

        function playVideo(e) {
            e.preventDefault();
            e.stopPropagation();
            var videoContainer = this.parentNode;
            var videoUrl = this.href;
            var videoId = videoUrl.split('.com/')[1];
            var iframeUrl = void 0;

            if (videoUrl.indexOf('vimeo') >= 0) {
                // vimeo
                videoId = parseVimeoId(videoUrl);
                iframeUrl = 'https://player.vimeo.com/video/' + videoId + '?autoplay=1';
                $.ajax({
                    type: 'GET',
                    url: 'https://vimeo.com/api/v2/video/' + videoId + '.json',
                    jsonp: 'callback',
                    dataType: 'jsonp',
                    success: function success(data) {
                        var thumbnail_src = data[0].thumbnail_large;
                        $('#thumb_wrapper').append('<img src="' + thumbnail_src + '"/>');
                    }
                });
            } else {
                // youtube
                videoId = videoId.split('v=')[1];
                iframeUrl = 'https://www.youtube.com/embed/' + videoId + '?autoplay=1';
                $('#thumb_wrapper').append('<img src="https://img.youtube.com/vi/' + videoId + '/hqdefault.jpg"/>');
            }

            videoContainer.innerHTML = '<iframe src="' + iframeUrl + '" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>';
        }
    });
}



